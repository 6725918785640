
export default {
  props: {
    file: {
      type: Object,
      default: null,
    },

    videoUrl: {
      type: String,
      default: null,
    },

    overlayImage: {
      type: Object,
      default: null,
    },

    caption: {
      type: String,
      default: null,
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    controls: {
      type: Boolean,
      default: true,
    },

    loop: {
      type: Boolean,
      default: false,
    },

    muted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPlaying: false,
      youtubeThumbnail: null,
    }
  },

  computed: {
    video() {
      return this.$refs.video
    },

    youtubePlayer() {
      return this.$refs.youtube?.player
    },

    videoId() {
      return this.$youtube.getIdFromUrl(this.videoUrl)
    },

    playerVars() {
      return {
        controls: this.controls,
        loop: this.loop,
        modestbranding: 1,
        showinfo: 0,
        muted: this.muted,
        rel: 0,
        cc_load_policy: 1,
        listType: 'playlist',
        autoplay: this.autoplay,
        playlist: this.videoId,
      }
    },
  },

  async mounted() {
    if (this.videoUrl && !this.overlayImage) {
      this.youtubeThumbnail = `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg`
      const imageWidth = await this.getImgWidth()
      // sometimes the youtube max res image is not available
      // it will return instead an error image that has a width of exact 120
      // when it returns that error image it should get instead the hqdefault image
      if (imageWidth === 120) {
        this.youtubeThumbnail = `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`
      }
    }
  },

  methods: {
    toggleVideo() {
      if (!this.isPlaying) {
        this.video?.play()
        this.youtubePlayer?.playVideo()

        this.isPlaying = true
      } else {
        this.video?.pause()
        this.youtubePlayer?.pauseVideo()
      }
    },

    getImgWidth() {
      return new Promise((resolve) => {
        const newImg = new Image()

        newImg.onload = function () {
          resolve(newImg.width)
        }

        newImg.src = this.youtubeThumbnail
      })
    },
  },
}
